import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Auth} from 'aws-amplify';


@Injectable({
  providedIn: 'root',
})
export class AuthCognitoService {

  private authenticationSubject: BehaviorSubject<any>;

  constructor() {
    this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }

  public signOut(): Promise<any> {
    return Auth.signOut()
      .then(() => {
        this.authenticationSubject.next(false);
      });
  }

  public isAuthenticated(): Promise<boolean> {
    if (this.authenticationSubject.value) {
      return Promise.resolve(true);
    } else {
      return this.getUser()
        .then((user: any) => {
          return !!user;
        }).catch(() => {
          return false;
        });
    }
  }

  public getUser(): Promise<any> {
    return Auth.currentUserInfo();
  }

  public federatedSignIn(): void {
    Auth.federatedSignIn()
      .then(() => {
        this.authenticationSubject.next(true);
      }).catch(e => console.log(e));
  }

}
