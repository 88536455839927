import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppComponent} from './app.component';
import {NotFoundComponent} from './not-found/not-found.component';

// routes
const appRoutes: Routes = [
  {path: '', redirectTo: 'app/nominations', pathMatch: 'full'},
  {
    path: 'app',
    component: AppComponent,
    children: [
      {
        path: 'nominations',
        loadChildren: () => import('./model/nominations/nominations.module').then(m => m.NominationsModule)
      },
      {
        path: 'transfer',
        loadChildren: () => import('./model/transfer/transfer.module').then(m => m.TransferModule)
      }
    ]
  },
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
