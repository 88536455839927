import { Component } from '@angular/core';

// Clarity Icons
import '@cds/core/icon/register.js';
import { ClarityIcons, curveChartIcon, switchIcon } from '@cds/core/icon';
ClarityIcons.addIcons(curveChartIcon, switchIcon );

declare var require: any;

const {version: appVersion} = require('../../../../package.json');

@Component({
  selector: 'app-left-menu',
  templateUrl: './application-left-menu.component.html',
  styleUrls: ['./application-left-menu.component.scss']
})
export class ApplicationLeftMenuComponent {

  public appVersion;
  collapsible = true;
  collapse = true;

  constructor() {
    this.appVersion = appVersion;
  }
}
