<clr-vertical-nav class="nav-trigger--bottom h-100 app-nav"
                  [clrVerticalNavCollapsible]="collapsible"
                  [(clrVerticalNavCollapsed)]="collapse">



  <a clrVerticalNavLink routerLink="nominations" routerLinkActive="active">
    <cds-icon clrVerticalNavIcon shape="curve-chart"></cds-icon>
    {{'nominations.title' | translate}}
  </a>
  <a clrVerticalNavLink routerLink="transfer" routerLinkActive="active">
    <cds-icon clrVerticalNavIcon shape="switch"></cds-icon>
    {{'transfer_details.title' | translate}}
  </a>

  <span class="version" *ngIf="!collapse">{{'application.version' | translate}} : {{appVersion}}</span>

</clr-vertical-nav>
