import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ClarityModule} from '@clr/angular';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {initializer} from './utils/app-init';
import {BootstrapComponent} from './root.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import {registerLocaleData} from '@angular/common';
import locale from '@angular/common/locales/fr-BE';
import {ToastrModule} from 'ngx-toastr';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppConfigService} from './config/app-config.service';
import {NotFoundComponent} from './not-found/not-found.component';

// menu
import { ApplicationLeftMenuComponent } from './menu/left/application-left-menu.component';
import { ApplicationTopMenuComponent } from './menu/top/application-top-menu.component';

registerLocaleData(locale);

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    BootstrapComponent,
    NotFoundComponent,
    ApplicationLeftMenuComponent,
    ApplicationTopMenuComponent,
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ClarityModule,
    BrowserAnimationsModule,
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-bottom-right'
    })
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'en-EN'},
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [AppConfigService]
    }
  ],
  exports: [],
  bootstrap: [BootstrapComponent]
})
export class AppModule {
}
