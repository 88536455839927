import { Component, OnInit } from '@angular/core';

import '@cds/core/icon/register.js';
import { ClarityIcons, homeIcon } from '@cds/core/icon';
ClarityIcons.addIcons(homeIcon);

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}

